<template>
  <div>
    <!-- 上半部 -->
    <div class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <!-- 批次操作 -->
        <div class="dropdown d-inline-block me-2 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchOrderBatch"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            批次操作
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchOrderBatch">
            <li>
              <button class="dropdown-item" @click="showModal('merge')">
                併單
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('paid')">
                已收款
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('packaged')">
                已包貨
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('shipped')">
                已寄出
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('allHeaderStatus')"
              >
                一鍵完成
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('unmerge')">
                解除併單
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('inspection')">
                撿貨總表
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('shipper')">
                撿貨/出貨單
              </button>
            </li>
          </ul>
        </div>
        <!-- 執行 -->
        <div class="dropdown d-inline-block me-2 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchOrderAction"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            執行
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchOrderAction">
            <li>
              <button class="dropdown-item" @click="showModal('exportExcel')">
                匯出
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('exportFamilyExcel')"
              >
                匯出好賣+
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('exportSevenExcel')"
              >
                匯出賣貨便
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('exportHilifeExcel')"
              >
                匯出萊賣貨市集
              </button>
            </li>
          </ul>
        </div>
        <!-- 常用搜尋 -->
        <div
          class="dropdown d-inline-block me-1 mb-1"
          v-if="$route.query.search !== 'trace'"
        >
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="FrequentlySearched"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            常用搜尋
          </button>
          <ul class="dropdown-menu" aria-labelledby="FrequentlySearched">
            <li>
              <button class="dropdown-item" @click="frequentlySearched('併單')">
                併單
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('已通知付款但未確認')"
              >
                已通知付款但未確認
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('未到貨')"
              >
                未到貨
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('全到貨')"
              >
                全到貨
              </button>
            </li>
          </ul>
        </div>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-danger"
          v-if="$route.query.search === 'trace'"
          @click="cancelTraceMode"
        >
          取消追查帳單模式
        </button>
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
          v-else
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p
          class="alert alert-danger fw-bolder text-center"
          v-if="$route.query.search === 'trace'"
        >
          追查帳單模式
        </p>
        <template v-else>
          <template v-if="recordAdvancedSearchData">
            <p>
              搜尋條件:
              <span class="badge bg-primary me-1 mb-1">
                帳單狀態: {{ recordAdvancedSearchData.singleSelect.billStatus }}
              </span>
              <span
                class="badge bg-primary me-1 mb-1"
                v-if="
                  recordAdvancedSearchData.singleSelect.shippedStatus !==
                  '不選擇'
                "
              >
                寄出狀態:
                {{ recordAdvancedSearchData.singleSelect.shippedStatus }}
              </span>
              <span
                class="badge bg-primary me-1 mb-1"
                v-if="
                  recordAdvancedSearchData.singleSelect.paidStatus !== '不選擇'
                "
              >
                收款狀態: {{ recordAdvancedSearchData.singleSelect.paidStatus }}
              </span>
              <span
                class="badge bg-primary me-1 mb-1"
                v-if="
                  recordAdvancedSearchData.singleSelect.mergeStatus !== '不選擇'
                "
              >
                併單狀態:
                {{ recordAdvancedSearchData.singleSelect.mergeStatus }}
              </span>
              <span
                class="badge bg-primary me-1 mb-1"
                v-if="
                  recordAdvancedSearchData.singleSelect.buyerNotifyStatus !==
                  '不選擇'
                "
              >
                顧客通知收款:
                {{ recordAdvancedSearchData.singleSelect.buyerNotifyStatus }}
              </span>
              <span
                class="badge bg-primary me-1 mb-1"
                v-if="
                  recordAdvancedSearchData.singleSelect.arrivalStatus !==
                  '不選擇'
                "
              >
                到貨狀態:
                {{ recordAdvancedSearchData.singleSelect.arrivalStatus }}
              </span>
              <span
                class="badge bg-primary me-1 mb-1"
                v-if="
                  recordAdvancedSearchData.singleSelect.searchByContent !==
                  '不選擇'
                "
              >
                依內容搜尋:
                {{ recordAdvancedSearchData.singleSelect.searchByContent }}
              </span>
            </p>
            <p>
              <span v-if="recordAdvancedSearchData.timeRange.switch">
                搜尋區間:
                <span
                  v-if="
                    recordAdvancedSearchData.timeRange.startTime &&
                    recordAdvancedSearchData.timeRange.endTime
                  "
                  >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                    recordAdvancedSearchData.timeRange.endTime
                  }}</span
                >
                <span
                  v-if="
                    recordAdvancedSearchData.timeRange.startTime &&
                    !recordAdvancedSearchData.timeRange.endTime
                  "
                  >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
                >
                <span
                  v-if="
                    !recordAdvancedSearchData.timeRange.startTime &&
                    recordAdvancedSearchData.timeRange.endTime
                  "
                  >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
                >&ensp;
              </span>
              <span v-if="recordAdvancedSearchData.shippedTimeRange.switch">
                寄出區間:
                <span
                  v-if="
                    recordAdvancedSearchData.shippedTimeRange.startTime &&
                    recordAdvancedSearchData.shippedTimeRange.endTime
                  "
                  >{{ recordAdvancedSearchData.shippedTimeRange.startTime }}~{{
                    recordAdvancedSearchData.shippedTimeRange.endTime
                  }}</span
                >
                <span
                  v-if="
                    recordAdvancedSearchData.shippedTimeRange.startTime &&
                    !recordAdvancedSearchData.shippedTimeRange.endTime
                  "
                  >{{
                    recordAdvancedSearchData.shippedTimeRange.startTime
                  }}開始</span
                >
                <span
                  v-if="
                    !recordAdvancedSearchData.shippedTimeRange.startTime &&
                    recordAdvancedSearchData.shippedTimeRange.endTime
                  "
                  >{{
                    recordAdvancedSearchData.shippedTimeRange.endTime
                  }}以前</span
                >&ensp;
              </span>
            </p>
          </template>
        </template>
      </div>
    </div>
    <!-- dataTable -->
    <BillDataTable
      :showWhitchModal="showWhitchModal"
      :frequentlySearchedStatus="frequentlySearchedStatus"
      @advancedSearchData="advancedSearchData"
    ></BillDataTable>
  </div>
</template>
<script>
// components
import BillDataTable from '../../../components/bill/BillDataTable.vue'

export default {
  components: {BillDataTable},
  data() {
    return {
      // 通知子層打開 modal 用
      showWhitchModal: {
        merge: false,
        paid: false,
        packaged: false,
        shipped: false,
        allHeaderStatus: false,
        unmerge: false,
        exportExcel: false,
        exportFamilyExcel: false,
        exportSevenExcel: false,
        exportHilifeExcel: false,
        inspection: false,
        shipper: false,
        advancedSearch: false,
      },
      frequentlySearchedStatus: null,
      recordAdvancedSearchData: null
    }
  },
  created() {},
  methods: {
    showModal(status) {
      this.showWhitchModal[status] = true
    },
    // 常用搜尋
    frequentlySearched(frequentlySearchedStatus) {
      this.frequentlySearchedStatus = frequentlySearchedStatus
    },
    advancedSearchData(object) {
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(object))
    },
    // 取消追查帳單模式
    cancelTraceMode() {
      this.$methods.switchLoading('show')
      let newQuery = JSON.parse(JSON.stringify(this.$route.query))
      delete newQuery.search
      delete newQuery.merchOrderId
      this.$router.replace({query: newQuery})
      setTimeout(() => {
        window.location.reload()
      }, 100)
    },
  }
}
</script>